<template>
    <div class="shortcuts-wrapper">
        <div class="shortcut-item" v-for="shortcut in shortcuts" @mouseleave="emit('keyDepressed',shortcut.keys)"
            @mouseenter="emit('keyPressed',shortcut.keys)">
            <div class="shortcut-name">{{ shortcut.name }}</div>
            <div class="shortcut-description">{{ shortcut.description }}</div>
        </div>
    </div>
</template>

<script setup>

import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const emit = defineEmits(['keyPressed','keyDepressed']);

const filtered_shortcuts = computed(() => {
    if (route && route.name) {

        return shortcuts.filter(s => {
            return s.pages.includes(route.name.toLowerCase());
        })
    } else {
        return shortcuts;
    }
})

const shortcuts = [
    {
        name: "1,2,3",
        description: "Toggle Momentum,Depth,Power",
        keys: ["49", "50", "51"],
        pages: ["browse"]
    },
    {
        name: "K",
        description: "Stop",
        keys: ["k"],
        pages: ["browse"]
    },
    {
        name: "L",
        description: "Play",
        keys: ["l"],
        pages: ["browse"]
    },
    {
        name: "Spacebar",
        description: "Play/Pause",
        keys: [" "],
        pages: ["browse"]
    },
    {
        name: "Enter",
        description: "Pause/Return to start",
        keys: ["13"],
        pages: ["browse"]
    },
    {
        name: "CMD/CTRL + Z",
        description: "Undo",
        keys: ["91", "z"],
        pages: ["browse"]
    },
    {
        name: "CMD/CTRL + SHIFT + Z",
        description: "Redo",
        keys: ["91", "z"],
        pages: ["browse"]
    },
    {
        name: "CMD/CTRL + C",
        description: "Copy",
        keys: ["91", "c"],
        pages: ["browse"]
    },
    {
        name: "CMD/CTRL + V",
        description: "Paste",
        keys: ["91", "v"],
        pages: ["browse"]
    },
    {
        name: "CMD/CTRL + S",
        description: "Save",
        keys: ["91", "S"],
        pages: ["browse"]
    },
    // {
    //     name: "Left Arrow",
    //     description: "Move Playhead one frame left",
    //     keys: ["37"],
    //     pages: ["browse"]
    // },
    // {
    //     name: "Right Arrow",
    //     description: "Move Playhead one frame right",
    //     keys: ["39"],
    //     pages: ["browse"]
    // },

    // {
    //     name: "Up/Down",
    //     description: "Previous / Next Song",
    //     keys: ["38"],
    //     pages: ["browse"]
    // }
]


onMounted(()=>{
    shortcuts.forEach(sho=>{
        console.log( sho )
        sho.keys.forEach(char=>{
            var key = document.querySelector('[data-char="' + char.toUpperCase() + '"]');
            if (!key) {
                key = document.querySelector('[data-key="' + char.toUpperCase() + '"]');
            }
            if( key ){
                key.setAttribute('data-has-shortcut',true);
            }
        })
    })
})


</script>

<style lang="scss" scoped>


.shortcut-item {
    display: flex;
    border-bottom: solid 1px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: .8em;
    padding: 2px 5px;
    padding-bottom: 3px;
    &:hover{
        background: var(--filmstro-black);
    }
}

.shortcut-description {
    margin-left: auto;
    color: #979797;
}

.keyboard-wrapper {
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
}


</style>